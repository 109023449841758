<template>
    <div class="main-pdding">
        <div class="main-box">
            <div class="main-between">
                <div class="title">
                    <label>产品列表</label>
                </div>
                <div class="new-product">
                    <el-button type="primary" icon="el-icon-plus" @click="addproduct">添加产品</el-button>
                </div>
            </div>
            <div class="search">
                <el-form inline :model="searchData" label-width="80px">
                    <el-form-item label="产品名称:">
                        <el-input class="search-input" v-model="searchData.name" placeholder="请输入产品名称"></el-input>
                    </el-form-item>
                    <el-form-item label="产品分类:">
                        <el-select class="search-input" v-model="searchData.classify" placeholder="请选择产品分类:">
                            <el-option v-for="classify in classifyData" :key="classify.id" :label="classify.name" :value="classify.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="添加时间:" prop="addTime">
                        <el-date-picker
                            class="search-input"
                            v-model="searchData.addTime"
                            @change="dateChange"
                            type="datetimerange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['00:00:00']"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="searchInfo">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="info-table">
                <infoTable :selection="false" :tableLoading="tableLoading" :tableHeadConfig="tableHead" :tableLoadData="tableLoadData">
                    <template v-slot:sort="slotData">
                        <div class="sort-sty cross-center">
                            <span>{{ slotData.data.row.sort }}</span>
                            <img src="@/assets/images/icon/modify-pen.png" style="width:60px;height:60px"/>
                        </div>
                    </template>
                    <template v-slot:good_name="slotData">
                        <div class="good-name cross-center">
                            <img class="left-img" :src="calpic(slotData.data.row.good_text)" style="width:60px;height:60px"/>
                            <span class="right-txt">{{ slotData.data.row.good_name }}</span>
                        </div>
                    </template>
                    <template v-slot:upLower="slotData">
                        <el-switch v-model="slotData.data.row.is_status" :active-value="1" :inactive-value="0" @change="stateChange(slotData.data.row)">
                        </el-switch>
                    </template>
                    <template v-slot:state="slotData">
                        <div v-if="slotData.data.row.is_status == 1" class="state-young">上架中</div>
                        <div v-else class="state-red">下架中</div>
                    </template>
                    <template v-slot:operation="slotData">
                        <div class="operation-sty">
                            <span @click="goEdit(slotData.data.row.id)"> 编辑 </span>
                            <span @click="delGood(slotData.data.row)"> 删除 </span>
                        </div>
                    </template>
                </infoTable>
            </div>

            <div class="bottom-pagination">
                <info-page @pageclcik="pageclcik" :pagination="pagination"></info-page>
            </div>
        </div>

        <deldialog :appId="appId" title="是否确定要删除该产品?" @dialogChange="delChange" :dialogVal="delDialog"></deldialog>
    </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
export default {
    mounted() {
        this.platformId = sessionGetKey("platformId")
        this.getGood()
        this.getClass()
    },
    data() {
        return {
            appId:'',
            delDialog:false,
            platformId: "",
            pagination: {
                currentpage: 1,
                total: 1,
                totalpage: 1,
                pageSize: 10,
            },
            searchData: {
                name: "",
                classify: "0",
                addTime:[]
            },
            classifyData: [
                {
                    name: "全部",
                    id: "0",
                },
            ],
            tableLoading: false,
            tableHead: [
                {
                    label: "ID",
                    field: "id",
                    sort: true,
                },
                {
                    label: "分类",
                    field: "name",
                },
                {
                    label: "产品名称",
                    field: "good_name",
                    columnType: "slot",
                    slotName: "good_name",
                    width: 250
                },
                {
                    label: "添加时间",
                    field: "created_at",
                    width: 200,
                },
                {
                    label: "是否上下架",
                    columnType: "slot",
                    slotName: "upLower",
                },
                {
                    label: "状态",
                    field: "is_status",
                    columnType: "slot",
                    slotName: "state",
                },
                {
                    label: "操作",
                    columnType: "slot",
                    slotName: "operation",
                },
            ],
            tableLoadData: [],
        };
    },
    methods: {
        searchInfo(){
            this.pagination.currentpage = 1
            this.getGood()
        },
        // 获取分类
        getClass(){
            const that = this;
            that.$request({
                url: that.$api.goodClass.getClass,
                method: "post",
                params: {
                    platform_id: that.platformId,
                },
            }).then((res) => {

                if(Array.isArray(res.data)){
                     this.classifyData = res.data;
                }else{
                      this.classifyData=Object.values(res.data)
                }

                // res.data.forEach(item => {
                //     this.classifyData.push(item)
                // });
            });
        },
        // 获取产品列表
        getGood() {
            const that = this;
            that.tableLoading = true;
            let {name,classify,addTime} = that.searchData
            let startTime = ''
            let endTime =''
            if(addTime){
                startTime = addTime[0]
                endTime = addTime[1]
            }

            that.$request({
                url: that.$api.good.getGood,
                method: "post",
                data: {
                    page: that.pagination.currentpage,
                    name:name,
                    cate:classify,
                    start_time:startTime,
                    end_time:endTime
                },
                params: {
                    platform_id: that.platformId,
                },
            }).then((res) => {
                that.pagination.currentpage = res.data.current_page;
                that.pagination.total = res.data.total;
                that.pagination.totalpage = res.data.last_page;
                that.pagination.pageSize = res.data.per_page;
                that.tableLoadData = res.data.data;
                that.tableLoading = false;
            });
        },
        // 是否上下架
        stateChange(row) {
            const that = this
            console.log(row,'row');
            that.$request({
                url: that.$api.good.editGood,
                method: "post",
                data:{
                    id:row.id,
                    is_status:row.is_status
                },
                params: {
                    platform_id:that.platformId,
                }
            }).then((res) => {
                that.getGood()
            });
        },
        //分页器
        pageclcik(e) {
            if (e) {
                this.pagination.currentpage = e;
                this.getGood();
            }
        },
        addproduct() {
            this.$router.push({
                path: "/productMent/list/addlist",
                query: {type:'1'}
            });
        },
        goEdit(id) {
            this.$router.push({ path: "/productMent/list/addlist", query: { id: id,type:'2'} });
        },
        delGood(item) {
            console.log(item.id);
            this.appId = item.id
            this.delDialog = true
        },
        // 删除产品
        delChange(type, id) {
            let that=this
            this.delDialog = false;
            console.log(type,id,'dididi');
            if (type === 1) {
                this.$request({
                    url: this.$api.good.delGood,
                    method: "post",
                    data: {
                        id: id,
                    },
                    params: {
                    platform_id:that.platformId,
                }
                }).then((res) => {
                    this.getGood();
                });
            }
        },
        dateChange(e){
            console.log(e,'时间');
        }
    },
    computed:{
        calpic(){
            return function(text){
                return JSON.parse(text).pic
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.title {
    label {
        display: inline-block;
        height: 30px;
        color: #4458fe;
        font-size: 20px;
        font-weight: 600;
        border-bottom: 2px solid #4458fe;
    }
}

.search {
    margin-top: 15px;
    .search-input {
        border-radius: 6px;
        border: 1px solid #999;
        /deep/.el-input__inner {
            border: 0 !important;
        }
    }
}

.info-table {
    min-height: 400px;

    .sort-sty {
        span {
            font-size: 16px;
        }

        img {
            margin-left: 10px;
            width: 22px;
            height: 22px;
        }
    }

    .state-young {
        width: 80px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #01d0b5;
        background-color: #e5fffc;
        border: 1px solid #01d0b5;
        border-radius: 6px;
    }

    .state-red {
        width: 80px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #fe725c;
        background-color: #ffe6e2;
        border: 1px solid #fe725c;
        border-radius: 6px;
    }

    .operation-sty {
        span {
            color: #4458fe;
            cursor: pointer;
        }

        span:before {
            content: "|";
            margin: 0 5px;
            color: #4458fe;
        }

        span:first-child:before {
            display: none;
        }
    }

    .good-name {
        .left-img {
            max-width: 80px;
            max-height: 80px;
        }

        .right-txt {
            margin-left: 10px;
        }
    }
}

.bottom-pagination {
    margin-top: 10px;
}
</style>
